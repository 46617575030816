import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// markup
const NotFoundPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <main>
        <div className='page-not-found'>
          <div className='wrapper'>
            <div className='not-found'>
              <div className='title'>404</div>
              <div className='subtitle'>Page Not Found</div>
            </div>
            <p className='no-mar light'>
              The page you are looking for might have been removed, had its name
              changed or is temporarily unavailable.
            </p>
            <Link to='/' className='button-link transparent'>
              Go to Home
            </Link>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default NotFoundPage;
